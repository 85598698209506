<template>
    <div class="page">
        <div class="school-box">
             <!-- 详情 -->
             <div v-if="detailTitle">
                <div class="bread-crumb">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item @click.native="change">美丽校园</el-breadcrumb-item>
                        <el-breadcrumb-item>{{detailTitle}}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <ul class="school-item">
                    <li v-for="item of detailList" :key="item.id">
                        <div class="cover-img">
                            <img :src="item.imgPath" alt="">
                        </div>
                    </li>
                </ul>
            </div>
            <div v-else>
                <ul class="school-item">
                    <li v-for="item of imgList" :key="item.id" @click="toDetail(item.id)">
                        <div class="cover-img">
                            <img :src="item.imgPath" alt="">
                        </div>
                        <p class="school-font">【组图】{{item.name}}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['imgList', 'detailList'],
        data() {
            return {
                detailTitle: '',

            }
        },
        watch: {
            detailList(val, oldVal) {
                if (val != oldVal) {
                    console.log(val);
                }
            }
        },
        methods: {
            toDetail(title) {
                this.detailTitle = title;
                this.$parent.getDetail(title);
            },
            change() {
                this.detailTitle = '';
                this.$parent.change(4, 'list');
            }
        }
    }
</script>
<style scoped lang="scss">
    .bread-crumb {
        cursor: pointer;
    }
    
    .school-item {
        display: flex;
        flex-wrap: wrap;
    }
    
    .school-item li {
        width: 48.9%;
        margin-right: 2%;
        margin-bottom: 16px;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        &:nth-child(2n) {
            margin-right: 0;
        }
        .school-font {
            position: absolute;
            width: 100%;
            height: 66px;
            line-height: 66px;
            padding-left: 20px;
            bottom: 0;
            left: 0;
            color: #fff;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            background: linear-gradient(180deg, rgba(13, 0, 0, 0) 0%, rgba(5, 0, 0, 0.8) 100%);
            box-sizing: border-box;
        }
    }
    
    .cover-img {
        height: 257px;
    }
    
    .cover-img img {
        position: relative;
    }
</style>