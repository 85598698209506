<template>
    <div class="page">
        <div class="img-box">
            <div v-for="(item) of imgList" :key="item.id" class="img-item">
                <div class="cover-img">
                    <img :src="item.imgPath" alt="">
                    <!-- <img src="../../assets/img/hour.jpg" alt=""> -->
                </div>
                <p>{{item.title}}</p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['imgList'],
        data() {
            return {}
        },
        created() {
            console.log(this.imgList);
        }
    }
</script>
<style scoped lang="scss">
    .img-box {
        display: flex;
        flex-wrap: wrap;
    }
    
    .img-item {
        width: 31.8%;
        margin-right: 2.1%;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        padding: 20px 20px 0;
        box-sizing: border-box;
        margin-bottom: 15px;
        padding-bottom: 20px;
        p {
            /* height: 63px; */
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-top: 15px;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #050000;
        }
        &:nth-child(3n) {
            margin-right: 0;
        }
    }
    
    .cover-img {
        height: 168px;
        object-fit: contain;
    }
    
    .cover-img img {
        object-fit: contain;
        position: relative;
    }
    
    @media screen and (max-width: 1200px) {
        .img-box .img-item {
            width: 48.9%;
            margin-right: 2%;
            padding-bottom: 20px;
            &:nth-child(2n) {
                margin-right: 0;
            }
            p {
                font-size: .37rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                /*控制在3行*/
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
    }
    
    @media screen and (max-width: 900px) {
        .page {
            padding-bottom: .4rem;
        }
    }
</style>