<template>
    <div class="page">
        <div class="leader-ship-team">
            <!-- 详情 -->
            <div v-if="detailTitle">
                <div class="bread-crumb">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item @click.native="change">领导班子</el-breadcrumb-item>
                        <el-breadcrumb-item>详情</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="leader-ship-box" style="padding-top: .27rem;">
                    <div class="detail-box">
                        <img :src="detailObj.icon" alt="">
                        <div>
                            <div class="detail-top"><h2>{{detailObj.name}}</h2><span>/{{detailObj.post}}</span></div>
                            <p>{{detailObj.detail}}</p>
                            <template v-if="detailObj.fileList">
                                <div v-for="item of detailObj.fileList" :key="item.id">
                                    <a :href="item.address">{{item.name}}</a>
                                </div>
                            </template>
<!-- <a v-if="detailObj.uploadFiles" :href="detailObj.uploadFiles">{{detailObj.uploadFiles}}</a> -->
</div>
</div>
</div>
</div>
<div v-else>
    <div class="leader-ship-box" v-for="item of leaderList" :key="item.title">
        <div class="title">{{item.type}}</div>
        <ul>
            <li v-for="ite of item.list" :key="ite.id">
                <div class="cover-img" v-if="ite.icon">
                    <img v-if="ite.icon.includes(',')" :src="ite.icon.split(',')[0]" alt="">
                    <img v-else :src="ite.icon" alt="">
                </div>
                <div class="leader-font">
                    <p>{{ite.name}}</p>
                    <span>{{ite.post}}</span>
                    <button @click="toDetail(ite.name,ite)">查看更多<img src="../../assets/img/to-more.png" alt=""></button>
                </div>
            </li>
        </ul>
    </div>
</div>
</div>
</div>
</template>
<script>
    export default {
        props: ['leaderList', 'detailObj'],
        data() {
            return {
                detailTitle: '',
            }
        },
        methods: {
            change() {
                this.detailTitle = '';
            },
            toDetail(title, item) {
                this.detailTitle = title;
                console.log(this.detailTitle);
                this.$parent.getDetail(item.id);
            },
        }
    }
</script>
<style scoped lang="scss">
    /* .leader-ship-team {
        max-width: 960px;
    } */
    
    .bread-crumb {
        cursor: pointer;
    }
    
    .leader-ship-box {
        width: 100%;
        /* max-width: 960px; */
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        padding: 66px 50px;
        box-sizing: border-box;
        position: relative;
        /* padding-bottom: 0; */
        margin-bottom: 20px;
        .title {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 36px;
            background: #C5181F;
            border-radius: 8px 0px 8px 0px;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 36px;
            text-align: center;
        }
    }
    /*     
    .cover-img {
        width: 100%;
        height: 310px;
        overflow: hidden;
        display: flex;
        align-items: center;
        background: #ddd;
        img {
            width: 100%;
            height: auto;
            position: relative;
        }
    } */
    
    .cover-img {
        position: relative;
        width: 100%;
        background: #ddd;
        border-radius: 8px 8px 0 0;
    }
    
    .cover-img:before {
        content: "";
        display: block;
        padding-top: 148%;
    }
    
    .cover-img img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 8px 8px 0 0;
    }
    
    .leader-ship-box ul {
        display: flex;
        flex-wrap: wrap;
        li {
            max-width: 225px;
            width: 26.1%;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 8px;
            border: 1px solid #E2E2E2;
            margin-bottom: 30px;
            margin-right: 10%;
            &:nth-child(3n) {
                margin-right: 0;
            }
            .leader-font {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                height: 120px;
                padding: 10px 0;
                box-sizing: border-box;
                /* height: calc(100% - 300px); */
            }
            p {
                font-size: 22px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #050000;
            }
            span {
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #186EC5;
            }
            button {
                width: 120px;
                height: 36px;
                border-radius: 18px;
                border: 1px solid #E2E2E2;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #050000;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 10px;
                    margin-left: 5px;
                }
            }
        }
    }
    
    .detail-box {
        display: flex;
        align-items: center;
        img {
            width: 200px;
            margin-right: 20px;
        }
        p {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
        }
        .detail-top {
            display: flex;
            align-items: flex-end;
            margin-bottom: 10px;
            h2 {
                font-size: 28px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #050000;
                margin-right: 20px;
            }
            span {
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #186EC5;
            }
        }
    }
    
    @media screen and (max-width: 1000px) {
        .leader-ship-box {
            padding: 1.27rem .27rem .27rem;
        }
        .detail-box {
            display: block;
            img {
                width: 4rem;
                height: auto;
                display: block;
            }
            p {
                font-size: .37rem;
            }
            .detail-top {
                h2 {
                    font-size: .43rem;
                    margin-right: .27rem;
                }
                span {
                    font-size: .32rem;
                }
            }
        }
    }
    
    @media screen and (max-width: 800px) {
        .leader-ship-box ul {
            li {
                max-width: 100%;
                width: 48%;
                margin-right: 3%;
                margin-bottom: .27rem;
                &:nth-child(2n) {
                    margin-right: 0;
                }
                &:nth-child(3n) {
                    margin-right: 3%;
                }
            }
        }
    }
</style>