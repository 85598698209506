import { render, staticRenderFns } from "./Sectoral.vue?vue&type=template&id=043a8d0e&scoped=true&"
import script from "./Sectoral.vue?vue&type=script&lang=js&"
export * from "./Sectoral.vue?vue&type=script&lang=js&"
import style0 from "./Sectoral.vue?vue&type=style&index=0&id=043a8d0e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "043a8d0e",
  null
  
)

export default component.exports