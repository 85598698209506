<template>
    <div class="page">
        <div class="top">
            <img :src="topPicture" alt="">
            <div class="know-jiaxiao"><p>{{topTitle}}</p></div>
        </div>
    </div>
</template>
<script>
    import {
        menuPic,
        phoneImg,
    } from "@/request/api";
    export default {
        props: ['topId', 'topTitle'],
        data() {
            return {
                topPicture: '',
                // 浏览器宽度
                screenWidth: 0,
            }
        },
        mounted() {
            // 首次加载时,需要调用一次
            this.screenWidth = window.innerWidth;
            this.setSize();
            // 窗口大小发生改变时,调用一次
            window.onresize = () => {
                this.screenWidth = window.innerWidth;
                this.setSize();
            };
        },
        methods: {
            // 通过浏览器宽度(图片宽度)计算高度
            setSize: function() {
                if (this.screenWidth <= 1000) {
                    this.phoneImg(this.topTitle)
                } else {
                    this.menuPic(this.topId);
                }
            },
            menuPic(id) {
                const _this = this;
                let data = {
                    id: id
                };
                menuPic(data).then(res => {
                    console.log('顶部图片');
                    _this.topPicture = res.data.picture;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            phoneImg(title) {
                const _this = this;
                let data = {
                    type: title
                };
                phoneImg(data).then(res => {
                    _this.topPicture = res.data.picPath;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
        }
    }
</script>
<style scoped lang="scss">
    @import "../../public/css/frame.scss";
    .top {
        min-height: 200px;
        background: #f8f8f8;
    }
</style>