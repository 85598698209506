<template>
    <div class="page">
        <div class="sectoral-left">
            <div class="sectoral-box">
                <p>高陵嘉园小学部门机构设置与负责人</p>
                <table>
                    <tr v-for="(item,index) of deptAndNameList" :key="index">
                        <td colspan="2">{{item.type}}</td>
                        <td>{{item.name}}</td>
                    </tr>
                </table>
            </div>
            <div class="sectoral-box">
                <p>中共高陵嘉园小学党支部委员会</p>
                <table>
                    <tr v-for="(item,index) of deptCommitList" :key="index">
                        <td>{{item.type}}</td>
                        <td>{{item.name}}</td>
                    </tr>
                </table>
            </div>
            <!-- <div class="sectoral-box">
                <p>中国共产主义青年团陕西师范大学嘉园小学支部委员会</p>
                <table>
                    <tr>
                        <td>书记</td>
                        <td>寇拥军</td>
                    </tr>
                    <tr>
                        <td>副书记</td>
                        <td>薛辕</td>
                    </tr>
                </table>
            </div> -->
        </div>
        <div class="sectoral-right">
            <div class="sectoral-box">
                <p>各部门联系电话</p>
                <table>
                    <tr v-for="item of options" :key="item.type">
                        <td>{{item.type}}</td>
                        <td>{{item.contact}}</td>
                    </tr>
                    <!-- <tr>
                        <td>教学研究中心</td>
                        <td>029-86965566</td>
                    </tr>
                    <tr>
                        <td>教育宣传中心</td>
                        <td>029-86965566</td>
                    </tr>
                    <tr>
                        <td>后勤管理服务中心</td>
                        <td>029-86965566</td>
                    </tr>
                    <tr>
                        <td>学生健康保障中心</td>
                        <td>029-86965566</td>
                    </tr>
                    <tr>
                        <td>学生成长中心(德育工作部)</td>
                        <td>029-86965566</td>
                    </tr>
                    <tr>
                        <td>学生成长中心(少先队工作部)</td>
                        <td>029-86965566</td>
                    </tr>
                    <tr>
                        <td>安保室（门卫室）</td>
                        <td>029-86965566</td>
                    </tr> -->
                </table>
            </div>
            
        </div>
        
    </div>
</template>
<script>
    import {
        deptSelect,
        deptAndName,
        deptCommittee
    } from "@/request/api";
    export default {
        data() {
            return {
                options: [],
                deptAndNameList: [],
                deptCommitList: [],
            }
        },
        created() {
            this.deptSelect();
            this.deptAndName();
            this.deptCommittee();
        },
        methods: {
            // 部门集合
            deptSelect() {
                const _this = this;
                deptSelect().then(res => {
                    _this.options = res.data;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            // 部门机构与负责人
            deptAndName() {
                const _this = this;
                deptAndName().then(res => {
                    _this.deptAndNameList = res.data;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            // 党支部委员会
            deptCommittee() {
                const _this = this;
                deptCommittee().then(res => {
                    _this.deptCommitList = res.data;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
        }
    }
</script>
<style scoped lang="scss">
    .page {
        display: flex;
        justify-content: space-between;
    }
    
    .sectoral-left,
    .sectoral-right {
        width: 48.9%;
    }
    
    .sectoral-box {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 20px;
        p {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #050000;
            text-align: center;
            margin-bottom: 20px;
        }
    }
    
    table {
        width: 100%;
        border: 1px solid #E2E2E2;
        border-collapse: collapse;
        border-spacing: 0px;
        tr {
            &:nth-child(2n) {
                background: #F8F8F8;
            }
        }
        td {
            height: 40px;
            border-right: 1px solid #E2E2E2;
            border-bottom: 1px solid #E2E2E2;
            text-align: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #050000;
            &:last-child {
                width: 40%;
            }
        }
    }
    
    @media screen and (max-width: 900px) {
        .page {
            display: block;
        }
        .sectoral-left,
        .sectoral-right {
            width: 100%;
        }
    }
</style>