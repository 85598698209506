<template>
    <div class="know">
      <!-- <div class="top">
          <img :src="topPicture" alt="">
          <div class="know-jiaxiao"><p>{{topText}}</p></div>
      </div> -->
      <HeaderPic :topId="topId" :topTitle="topText"></HeaderPic>
      <div class="know-main">
        <div class="know-left">
          <ul class="know-table-list">
            <li :class="{ active: ind == index }" @click="change(index)" v-for="(item,index) of tableList" :key="index">
              <img v-if="ind == index" src="../assets/img/know-school.png" alt=""><span>{{item}}</span>
            </li>
          </ul>
        </div>
        <div class="know-right">
          <div class="know-title">
              <span>{{tableList[ind]}}</span>
              <img src="../assets/img/know-right-title.png" alt="">
          </div>
          <!-- 学校简介 -->
          <div v-if="ind==0" class="know-main-right">
            <ul>
              <li v-for="(item,index) of conList" :key="index">
                <div class="title">
                  <img :src="item.img" alt="">
                  <span>{{item.title}}</span>
                </div>
                <div class="content">{{item.con}}</div>
              </li>
            </ul>
          </div>
          <!-- 领导班子 -->
          <LeaderShip :leaderList="leaderList" :detailObj="detailObj" v-if="ind==1"></LeaderShip>
          <!-- 部门机构 -->
          <Sectoral v-if="ind==2"></Sectoral>
          <!-- 学校荣誉 -->
          <Imgbox v-if="ind==3" :imgList="honerList"></Imgbox>
          <!-- 美丽校园 -->
          <School v-if="ind==4" :detailList="detailList" :imgList="schoolList"></School>
          <el-pagination :page-size="pageSize" :current-page.sync="pageNum" @current-change="handleCurrentChange" v-if="(ind==3||ind==4)&&pageShow" layout="prev, pager, next" :total="total"></el-pagination>
        </div>
      </div>
    </div>
  </template>
  <script>
      import HeaderPic from '../components/HeaderPic.vue'
      import LeaderShip from '../components/Knows/LeaderShip.vue'
      import Sectoral from '../components/Knows/Sectoral.vue'
      import Imgbox from '../components/Knows/Imgbox.vue'
      import School from '../components/Knows/School.vue'
      import {
          schoolProfile,
          leaderGroupList,
          leaderGroupDetail,
          schoolHonor,
          beautifulSchool,
          beautifulSchoolDetail,
      } from "@/request/api";
      export default {
          components: {
              HeaderPic,
              LeaderShip,
              Sectoral,
              Imgbox,
              School
          },
          data() {
              return {
                  topPicture: '',
                  topText: '',
                  topId: '',
                  tableList: [],
                  ind: 0,
                  conList: [{
                      id: 1,
                      img: require('../assets/img/know-title-01.png'),
                      title: '学校概况',
                      con: '      高陵嘉园小学地处千年古都西安之北，毗邻泾渭两河，位于西安市高陵区崇皇街道高茹路九横路十字西南，是一所年轻、充满着朝气和活力的学校。学校占地45亩，建筑面积3.5万平方米，设计规模48个教学班，可容纳2000名学生学习生活。学校于2020年9月开学，目前共有1到6年级21个教学班，学生851人，教职员工74人。学校环境优美、交通便利、设施精良，是一所高起点、高标准、智能化、具有国际视野的现代学校。'
                  }, {
                      id: 2,
                      img: require('../assets/img/know-title-02.png'),
                      title: '校园环境',
                      con: '      学校按照国家标准科学规划，合理设计。欧式风格教学楼、下沉式庭院设计鸿图华构、典雅气派。校园内花圃绿植环绕，教室宽敞舒适，餐厅整洁明亮，宿舍温馨和谐，高标准的图书馆、体育馆、创客中心、科学实验中心、形体舞蹈中心、美术、书法、陶艺等专用教室、活动场所一应俱全。信息化、网络化、智能化的教学与管理设施设备配置充足多样，是一所教学、科研、管理和校园生活充分融合的智慧校园。'
                  }, {
                      id: 3,
                      img: require('../assets/img/know-title-03.png'),
                      title: '学校文化',
                      con: '      学校以“家国情怀、世界担当” 为办学理念，贯彻落实“有差异的优异——人人都可成才，人人皆可出彩”教育思想，培育具有“爱、担当、美好、分享、未来 ”五有理念的优秀学子。以“尽善尽美”为精神使命，追求“嘉园有爱，分享美好；使命担当，民族梦想”的教育愿景。营造以“以善载道、以美育人，以爱施教”的教师文化，以“培育家国情怀，激发使命担当”的学生文化，以凝聚教育合力为目的“与人为善，言行兼美”的家长文化，进而创建多维度的“善美共进体”管理模式，共同形成“嘉”文化为魂的学校品牌。'
                  }, {
                      id: 4,
                      img: require('../assets/img/know-title-04.png'),
                      title: '课程建设',
                      con: '      学校构建“课堂教学、活动拓展、人文环境”三位一体的育人格局。以课堂教学为核心，设立国学修养和创客智造两大课程模块，形成中国传统文化与创客教育活动相得益彰的校本课程体系；以活动拓展为载体，倡导“一班一品”、课内课外一体、网上网下同步，开展形式多样的校园文化活动。学校用“嘉”文化特色融入校园整体规划中，创建传统文化为内涵、信息技术为支撑的现代化校园，在蕴含着传统文化的审美观念、价值情怀的气息中，在网络时代更新换代、日新月异的创新中，引导学生具有温文尔雅、追求卓越的人格特质。'
                  }, {
                      id: 5,
                      img: require('../assets/img/know-title-05.png'),
                      title: '校园活动',
                      con: '      学校“钟爱有嘉”社团课程积极开发各方资源，形成以“音美艺术”“运动健康”“科技益智”“技能拓展”为主的四大类近四十个社团，将“第二课堂”与课后服务相结合，全力促进“双减”之下学生在校生活的丰富性，积极发展学生个性和特长。学校还充分利用各大节日、纪念日，组织开展了丰富多彩的校园活动，促进学生综合素养的提升。'
                  }, {
                      id: 6,
                      img: require('../assets/img/know-title-06.png'),
                      title: '教师队伍',
                      con: '      学校由陕西师范大学委派的强大管理团队和中高级教师、省市级学科带头人和教学能手组成教师核心骨干。现有省级名师工作坊2个，学校获得省级学科带头人、省级教学能手、市级教学能手、区级骨干教师称号的共15人次，占比达到25%。教师教科研成果显著，在陕西省第二届中小学教育教学成果评比、西安市2021年度教育教学科研成果评比中学校17位教师获奖；2位老师分别荣获西安市信息化教学大赛创新教学课堂一等奖和高陵区中小学教师教学技能大赛优秀奖。学校通过事业聚人、高薪聘人、发展待人、情感留人等举措，组建了一支师德师风优秀、教学经验丰富、教学效果良好、拥有奉献精神的优秀教师团队，为学校发展和学生教育奠定了坚实的基础。'
                  }, {
                      id: 7,
                      img: require('../assets/img/know-title-01.png'),
                      title: '学校荣誉',
                      con: '      办学以来，学校办学质量进一步提升，办学成果丰硕。全校师生传承“尽善尽美”的精神使命，以“嘉文化”品牌特色凸显，收获了跨越发展的累累硕果。学校授牌“中—加—美”英语浸入式实验基地；2020年年底学校餐厅被高陵区市场监管局授予“2020年度食品安全优秀学校食堂”称号；授牌西安思源学院教育学院实践基地；授牌崇皇中心小学低段数学中心教研组集中教研点；荣获体育道德风尚奖；现有省级名师工作坊2个；2020年承办了高陵区中小学美术教师研修暨名师名家名课交流展示活动；承办高陵区2020年小学生“三跳”运动会；信息化发展迈向新台阶，获得国家版权局颁发的计算机软件著作权登记证书；2021年学校荣获“区级文明校园”荣誉称号。'
                  }],
                  leaderList: [{
                      title: '党支部班子',
                      list: [{
                          id: 1,
                          name: '寇拥军',
                          posts: '校长',
                          img: require('../assets/img/kou.jpg'),
                          desc: '寇拥军，中共党员，高级教师，法学硕士，教育博士在读。1993年分配到陕西师范大学附属小学工作，历任教师、主任、校长助理。2013年至2018年在陕西师范大学绿地浐灞小学担任党支部书记、副校长。2019年担任高陵嘉园小学支部书记、校长至今。工作期间，先后获得陕西省第二批学科带头人、陕西省优秀教学能手、中小学网站建设全国先进工作者等荣誉称号。现为教育部陕西师范大学基础教育课程研究中心职后教育研究分中心副主任、陕西师范大学未来教育研究中心研究员、西安市高陵区督学。'
  
                      }, {
                          id: 2,
                          name: '寇拥军',
                          posts: '副校长',
                          img: require('../assets/img/kou.jpg'),
  
                      }],
                  }, {
                      title: '行政班子',
                      list: [{
                          id: 1,
                          name: '邵武',
                          posts: '教务主任',
                          img: require('../assets/img/kou.jpg'),
  
                      }, {
                          id: 2,
                          name: '尤东',
                          posts: '德育主任',
                          img: require('../assets/img/kou.jpg'),
  
                      }, {
                          id: 3,
                          name: '阚影',
                          posts: '教研主任',
                          img: require('../assets/img/kou.jpg'),
  
                      }, {
                          id: 4,
                          name: '泮毓',
                          posts: '总务主任',
                          img: require('../assets/img/kou.jpg'),
  
                      }, {
                          id: 5,
                          name: '缪芝芳',
                          posts: '大队辅导员',
                          img: require('../assets/img/kou.jpg'),
  
                      }, ]
                  }],
                  honerList: [{
                      id: 1,
                      img: require('../assets/img/hour.jpg'),
                      title: '“西安市高陵区优秀少先队中队”称号'
                  }, {
                      id: 2,
                      img: require('../assets/img/hour.jpg'),
                      title: '“西安市高陵区优秀少先队中队”称号'
                  }, {
                      id: 3,
                      img: require('../assets/img/hour.jpg'),
                      title: '“西安市高陵区优秀少先队中队”称号'
                  }],
                  schoolList: [{
                      id: 1,
                      img: require('../assets/img/school.jpg'),
                      title: '学校正门'
                  }, {
                      id: 2,
                      img: require('../assets/img/school.jpg'),
                      title: '嘉和楼'
                  }, {
                      id: 3,
                      img: require('../assets/img/school.jpg'),
                      title: '教学楼'
                  }, {
                      id: 4,
                      img: require('../assets/img/school.jpg'),
                      title: '操场'
                  }, ],
                  detailList: [],
                  detailObj: {},
                  // 分页
                  total: 0,
                  pageNum: 1,
                  pageSize: 6,
                  pageShow: true,
              }
          },
          created() {
              window.scroll(0, 0);
              this.initTableList();
  
              this.schoolProfile();
          },
          mounted() {
              if (this.$route.query.id) {
                  this.ind = this.$route.query.id - 1;
                  this.change(this.ind);
              }
          },
          watch: {
              $route: {
                  handler(val, oldval) {
                      console.log(val.query.id); //新路由信息
                      this.ind = this.$route.query.id - 1;
                      this.change(this.ind);
                  },
                  // 深度观察监听
                  deep: true
              }
          },
  
          methods: {
              initTableList() {
                  this.tableList = []
                  for (let item of this.headerList[1].content) {
                      this.tableList.push(item.text)
                  }
                  this.topText = this.headerList[1].title;
                  this.topId = this.headerList[1].id;
                  // this.menuPic(this.headerList[1].id);
              },
              change(index, type) {
                  this.ind = index;
                  if (this.ind == 0) this.schoolProfile();
                  else if (this.ind == 1) this.leaderGroupList();
                  else if (this.ind == 3) {
                      this.pageNum = 1;
                      this.schoolHonor();
                  } else if (this.ind == 4) {
                      if (!type) {
                          this.ind = index;
                          this.beautifulSchool();
                      }
                      this.pageNum = 1;
                      this.pageSize = 4;
                      this.pageShow = true;
                  }
              },
              getDetail(id) {
                  if (this.ind == 1) this.leaderGroupDetail(id);
                  else if (this.ind == 4) this.beautifulSchoolDetail(id);
              },
              handleCurrentChange(val) {
                  this.pageNum = val;
                  if (this.ind == 3) this.schoolHonor();
                  if (this.ind == 4) this.beautifulSchool();
              },
              // 学校简介
              schoolProfile() {
                  const _this = this;
                  schoolProfile().then(res => {
                      let item;
                      for (let i = 0; i < _this.conList.length; i++) {
                          item = _this.conList[i];
                          if (i == 0) item.con = res.introduce;
                          if (i == 1) item.con = res.environment;
                          if (i == 2) item.con = res.culture;
                          if (i == 3) item.con = res.course;
                          if (i == 4) item.con = res.activity;
                          if (i == 5) item.con = res.teachers;
                          if (i == 6) item.con = res.honor;
                      }
                  }).catch((error) => {
                      // error
                      console.log(error)
                  })
              },
              // 领导班子
              leaderGroupList() {
                  const _this = this;
                  leaderGroupList().then(res => {
                      _this.leaderList = res.data;
                  }).catch((error) => {
                      // error
                      console.log(error)
                  })
              },
              leaderGroupDetail(id) {
                  const _this = this;
                  let data = {
                      id: id
                  };
                  leaderGroupDetail(data).then(res => {
                      _this.detailObj = res;
                  }).catch((error) => {
                      // error
                      console.log(error)
                  })
              },
              schoolHonor() {
                  const _this = this;
                  let data = {
                      pageNum: _this.pageNum,
                      pageSize: 6
                  }
                  schoolHonor(data).then(res => {
                      _this.honerList = res.rows;
                      _this.total = res.total;
                      if (_this.total == 0) _this.pageShow = false;
                      else _this.pageShow = true;
                  }).catch((error) => {
                      // error
                      console.log(error)
                  })
              },
              // 美丽校园
              beautifulSchool() {
                  const _this = this;
                  let data = {
                      pageNum: _this.pageNum,
                      pageSize: _this.pageSize
                  };
                  beautifulSchool(data).then(res => {
                      _this.schoolList = res.rows;
                      _this.total = res.total;
                      if (_this.total == 0) _this.pageShow = false;
                      else _this.pageShow = true;
                  }).catch((error) => {
                      // error
                      console.log(error)
                  })
              },
              beautifulSchoolDetail(id) {
                  const _this = this;
                  let data = {
                      id: id
                  }
                  beautifulSchoolDetail(data).then(res => {
                      _this.detailList = res;
                      _this.pageShow = false;
                  }).catch((error) => {
                      // error
                      console.log(error)
                  })
              }
          }
      }
  </script>
  <style scoped lang="scss">
      @import "../../public/css/frame.scss";
      @media screen and (max-width: 1200px) {
          .know-main-right .title span {
              display: block;
              line-height: 0.6rem;
              font-size: 26px;
              padding-left: 34px;
          }
      }
      
      @media screen and (max-width: 770px) {
          .know-main-right .title img {
              height: 0.6rem;
          }
          .know-main-right .title span {
              font-size: .28rem;
              padding-left: 24px;
          }
      }
  </style>